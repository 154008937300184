//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SplitView from '~/components/SplitView.vue';
import { windowResizeMixin } from '../mixins/resize';

export default {
  name: 'AboveTheFold',
  mixins: [windowResizeMixin],
  components: {
    SplitView,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    imgUrl: {
      type: String,
      default: '',
    },
    noArrow: Boolean,
  },
  computed: {
    imgWidth() {
      return this.mixinResize.initialWidth;
    },
    imgHeight() {
      return Math.ceil((this.imgWidth) / 1.34);
    },
  },
};
