import { render, staticRenderFns } from "./AboveTheFold.vue?vue&type=template&id=05f4cf3e&scoped=true&"
import script from "./AboveTheFold.vue?vue&type=script&lang=js&"
export * from "./AboveTheFold.vue?vue&type=script&lang=js&"
import style0 from "./AboveTheFold.vue?vue&type=style&index=0&id=05f4cf3e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05f4cf3e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SplitView: require('/opt/build/repo/apps/shop/components/storyblok/SplitView/SplitView.vue').default})
